













import { Component, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'

@Component({
  components: {
    LabelBase,
    ColoredBox,
  },
})
export default class EntranceTeacherHistoryHeader extends Vue {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()

  get isActive(): any {
    return function (target: string): { [key: string]: boolean } {
      // @ts-ignore
      const { path } = this.$route
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active,
      }
    }
  }
}
